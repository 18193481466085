export const pinnedPosts = {
  'post-SnoEXyjfb': 1, // Central London
  'post-Tr_mpgaQa': 2, // West London
  'post-gY4aZU3Bp': 3, // North London
  'post-4kIpSfi_1': 4, // East London
  'post-imHaQuVG8': 5, // South London
}

export const pinnedMaps = {
  'post-cskq0m1JS': 1, // Central London
  'post-T-n-RX8Bd': 2, // North London
  'post-FaC22fD6B': 3, // East London
  'post-s2h35RC0T': 4, // South London
  'post-63XikKKZh': 5, // West London
}

// Central London; West London; North London; East London; South London

export const orderedCategories = {
  ATTRACTIONS: 1,
  MAPS: 2,
  'DAY TRIPS': 3,
  OTHER: 4,
}

export const SEOTitles = {
  index: {
    title: 'Home',
    description: '',
  },
  aboutUs: {
    title: 'About Us',
    description: '',
  },
  contactUs: {
    title: 'Contact Us',
    description:
      'Want To Get In Touch? Feel free to contact us using our contact form below or message us directly at info@london-travel.co.uk...',
  },
  thankYou: {
    title: 'Thank You',
    description: '',
  },
  gallery: {
    title: 'Our Gallery',
    description:
      'Welcome to our Gallery || See our latest images shared with you on our Instagram account',
  },
  quiz: {
    title: 'Our Quiz',
    description:
      'Welcome to the London Travel Quiz || Our quiz has a database of over 70 questions, make sure to stop by and test your knowledge!',
  },
  quizResults: {
    title: 'Your Results',
    description:
      'Welcome to the London Travel Quiz || Our quiz has a database of over 70 questions, make sure to stop by and test your knowledge!',
  },
  legal: {
    title: 'Legal',
    description:
      'Welcome to London-Travel.co.uk || Find out about the TOA and other information behind London-Travel.co.uk',
  },
  notFound: {
    title: 'Not found',
    description:
      'Woops, something went wrong. This page does not exist or is no longer reachable. You seem to have hit a dead end.',
  },
}
